import React, { Fragment, useContext } from 'react'
import HorizontalDivider from '../../Divider/HorizontalDivider';
import './ServiceRecordSessions.css';
import { useHistory } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import UserContext from '../../../store/user-context';


function ServiceRecordSessions(props) {

    const history = useHistory();
    const userCtx = useContext(UserContext)

    const getServiceRecord = (dateString) => {
        let yourDate = new Date(dateString)
        return yourDate.toISOString().split('T')[0]
    }

    const editRecordDate = (sessionidValue, sessionRef) => {
        userCtx.setRecordServiceItemIndex(props.index)
        userCtx.setRecordServiceItemRef(sessionRef)
        console.log(props.servicePeriodData)
        history.push({
            pathname: "/editServiceRecord",
            state: {
                sessionId: sessionidValue,
                parameters: props.parametersDate,
                servicePeriodData: props.servicePeriodData,
                clientName: props.clientName
            }
        });
    }

    const test = true;
    return (
        <Fragment>
            {
                props.sessions.map((value, index) =>
                    <div>
                        <HorizontalDivider />
                        <div className='record__session_container'
                            id={`session${props.index + index}`}>
                            <p className='record_session_title'>Date of Service:</p>
                            <p className='record_session_description'>{getServiceRecord(value.from)}</p>
                        </div>
                        <div className='record__session_container'>
                            <p className='record_session_title'>Session:</p>
                            <p className='record_session_description'>{value.type}, {value.status}</p>
                        </div>
                        <div className='record__session_container'>
                            <p className='record_session_title'>Provider Title:</p>
                            <p className='record_session_description'>{value.title} {value.plan == false && <Fragment ><font color="red">&nbsp;&nbsp;&nbsp;NO Treatment Plan found for this title for this client</font></Fragment>}</p>
                        </div>
                        <div className='table_service_record'>
                            <table className='table_service'>
                                <tr>
                                    <th className='th_service'><p className='record_session_title2'>Service Description</p></th>
                                    <th className='th_service'><p className='record_session_title2'>Quantity - Taxable</p></th>
                                </tr>
                                <tr>
                                    {value.service.rehab_support.label == null && <td className='td_service'>Rehab Support</td>}
                                    {value.service.rehab_support.label != null && <td className='service__title__color' style={{ color: value.service.rehab_support.label.color }}>{value.service.rehab_support.label.text}</td>}
                                    <td className='td_service'>
                                        <div className='td_container_items'>
                                            <p>{value.service.rehab_support.hours}</p>
                                            <p className='column__container'>Hrs {value.service.rehab_support.taxable && <Fragment>&nbsp;T</Fragment>}{!value.service.rehab_support.taxable && <Fragment>&nbsp;&nbsp;&nbsp;</Fragment>}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    {value.service.travel_time.label == null && <td className='td_service'>Travel Time</td>}
                                    {value.service.travel_time.label != null && <td className='service__title__color' style={{ color: value.service.travel_time.label.color }}>{value.service.travel_time.label.text}</td>}
                                    <td className='td_service'><div className='td_container_items'>{value.service.travel_time.hours} <p className='column__container'>Hrs {value.service.travel_time.taxable && <Fragment>&nbsp;T</Fragment>}{!value.service.travel_time.taxable && <Fragment>&nbsp;&nbsp;&nbsp;</Fragment>}</p></div></td>
                                </tr>

                                <tr>
                                    {value.service.travel_mileage.label == null && <td className='td_service'>Travel Mileage</td>}
                                    {value.service.travel_mileage.label != null && <td className='service__title__color' style={{ color: value.service.travel_mileage.label.color }}>{value.service.travel_mileage.label.text}</td>}
                                    <td className='td_service'><div className='td_container_items'>{value.service.travel_mileage.distance} <p className='column__container'>Kms {value.service.travel_mileage.taxable && <Fragment>&nbsp;T</Fragment>}{!value.service.travel_mileage.taxable && <Fragment>&nbsp;&nbsp;&nbsp;</Fragment>}</p></div></td>
                                </tr>

                                <tr>
                                    {value.service.activity_mileage.label == null && <td className='td_service'>Activity Mileage</td>}
                                    {value.service.activity_mileage.label != null && <td className='service__title__color' style={{ color: value.service.activity_mileage.label.color }}>{value.service.activity_mileage.label.text}</td>}
                                    <td className='td_service'><div className='td_container_items'>{value.service.activity_mileage.distance} <p className='column__container'>Kms {value.service.activity_mileage.taxable && <Fragment>&nbsp;T</Fragment>}{!value.service.activity_mileage.taxable && <Fragment>&nbsp;&nbsp;&nbsp;</Fragment>}</p></div></td>
                                </tr>
                                <tr>
                                    {value.service.activity_expense.label == null && <td className='td_service'>Activity Expenses</td>}
                                    {value.service.activity_expense.label != null && <td className='service__title__color' style={{ color: value.service.activity_expense.label.color }}>{value.service.activity_expense.label.text}</td>}
                                    <td className='td_service'><div className='td_container_items'>${value.service.activity_expense.amount} <p className='column__container'> {value.service.activity_expense.taxable && <Fragment>&nbsp;T</Fragment>}{!value.service.activity_expense.taxable && <Fragment>&nbsp;&nbsp;&nbsp;</Fragment>}</p></div></td>
                                </tr>
                                <tr>
                                    {value.service.documentation.label == null && <td className='td_service'>Documentation</td>}
                                    {value.service.documentation.label != null && <td className='service__title__color' style={{ color: value.service.documentation.label.color }}>{value.service.documentation.label.text}</td>}
                                    <td className='td_service'><div className='td_container_items'>{value.service.documentation.hours} <p className='column__container'>Hrs {value.service.documentation.taxable && <Fragment>&nbsp;T</Fragment>}{!value.service.documentation.taxable && <Fragment>&nbsp;&nbsp;&nbsp;</Fragment>}</p></div></td>
                                </tr>
                                <tr>
                                    {value.service.communication.label == null && <td className='td_service'>Communication</td>}
                                    {value.service.communication.label != null && <td className='service__title__color' style={{ color: value.service.communication.label.color }}>{value.service.communication.label.text}</td>}
                                    <td className='td_service'><div className='td_container_items'>{value.service.communication.hours}<p className='column__container'> Hrs {value.service.communication.taxable && <Fragment>&nbsp;T</Fragment>}{!value.service.communication.taxable && <Fragment>&nbsp;&nbsp;&nbsp;</Fragment>}</p></div></td>
                                </tr>

                            </table>

                        </div>

                    </div>
                )
            }
        </Fragment>
    )
}

export default ServiceRecordSessions