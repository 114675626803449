import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import Toolbar from '../../components/Toolbar/Toolbar'
import SelectDateModal from '../../components/UI/SelectDateModal/SelectDateModal'
import AuthContext from '../../store/auth-context';
import API from '../../utils/API';
import ServiceRecordItem from './ServiceRecordItem';
import './ServiceRecord.css';
import SendInvoiceModal from '../../components/UI/SendInvoiceModal/SendInvoiceModal';
import ToastUI from '../../components/UI/ToastUI/ToastUI';


function ServiceRecord(props) {
    const [showDateModal, setShowDateModal] = useState(false);
    const [showSendInvoice, setShowSendInvoice] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordList, setRecordList] = useState([]);
    const [servicePeriod, setServicePeriod] = useState("");
    const [providerTotal, setProviderTotal] = useState("");
    const [parametersDate, setParametersDate] = useState("");
    const [servicePeriodFrom, setServicePeriodFrom] = useState("");
    const [servicePeriodTo, setServicePeriodTo] = useState("");
    const [subtotal, setSubtotal] = useState("");
    const [hst, setHst] = useState("");
    const [grandTotal, setGrandTotal] = useState("");
    const location = useLocation()
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        if (location.state.parameters != null && location.state.parameters != '') {
            getServiceRecord(location.state.parameters, location.state.servicePeriodData)
        } else {
            setShowDateModal(true)
        }
    }, []);

    const getServiceRecord = (parameters, servicePeriodData, from, to) => {
        setParametersDate(parameters)
        setServicePeriod(servicePeriodData)
        setServicePeriodFrom(from)
        setServicePeriodTo(to)
        setShowDateModal(false)
        fetchData(parameters, servicePeriodData)
    }

    const fetchData = async (parameters, servicePeriodData) => {
        //console.log(parameters)
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get(`/v2/services/record/${parameters}`, config);
            //console.log(response)
            let statusCode = response.data.status
            if (statusCode == 200) {
                if (response.data.message.result.record != null) {
                    //console.log(response)
                    setRecordList(response.data.message.result.record)
                    setProviderTotal(response.data.message.result.total)
                    if (response.data.message.result.invoice != null) {
                        setSubtotal(response.data.message.result.invoice.subtotal)
                        setHst(response.data.message.result.invoice.hst)
                        setGrandTotal(response.data.message.result.invoice.grandtotal)
                    }
                }
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const openSelectDate = () => {
        setShowDateModal(true)
    }

    const openSendInvoice = () => {
        setShowSendInvoice(true)
    }

    const sendInvoiceTo = async (email, isPreview) => {
        setShowSendInvoice(false)
        const bodyData = {
            email: email,
            from: servicePeriodFrom,
            to: servicePeriodTo,
            preview: isPreview
        }
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.put(`/v2/provider/invoice`, bodyData, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                toast.success(
                    ({ }) => (<ToastUI title={response.data.message.result.message} />),
                    { position: toast.POSITION.TOP_CENTER, draggable: true }
                )
            } else if (statusCode >= 400 && statusCode < 500) {
                toast.error(
                    "Error",
                    { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
                );
            }
        } catch (e) {
            toast.error(
                "Error",
                { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
            );
        }
    }

    return (
        <div>
            <Toolbar
                enableBackNavigation={false}
                fixedToolBar={false}
                titleScreen={"Service Record"}
                showRefreshIcon={false}
                selectDate={true}
                openSelectDate={openSelectDate}
                pdfServiceRecord={true}
                openSendInvoice={openSendInvoice}
            />
            <ToastContainer
                hideProgressBar={false}
            />
            <SelectDateModal
                show={showDateModal}
                setShowDateModal={setShowDateModal}
                getServiceRecord={getServiceRecord}
            />
            <SendInvoiceModal
                show={showSendInvoice}
                setShowSendInvoice={setShowSendInvoice}
                sendInvoiceTo={sendInvoiceTo}
            />
            {
                isLoading &&
                <FullScreenLoader />
            }
            {
                !isLoading &&
                <Fragment>
                    <div className='info__container'>
                        <p className='title_period'>Period: {servicePeriod} </p>
                        <div className='total__container'>
                            <div class="total__column">
                                Subtotal:
                            </div>
                            <div class="total__column">
                                HST:
                            </div>
                            <div class="total__column">
                                Grand Total:
                            </div>
                            <div class="total__column">
                                {subtotal}
                            </div>
                            <div class="total__column">
                                {hst}
                            </div>
                            <div class="total__column">
                                {grandTotal}
                            </div>
                        </div>
                    </div>
                    <ServiceRecordItem
                        parametersDate={parametersDate}
                        recordList={recordList}
                        servicePeriod={servicePeriod}
                    />
                </Fragment>
            }
        </div>
    )
}

export default ServiceRecord