import React, { useEffect, useState, useContext, Fragment } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import UserContext from '../../../store/user-context';
import './SendInvoiceModal.css';
import Loader from 'react-loader-spinner'
import AuthContext from '../../../store/auth-context';
import API from '../../../utils/API';

function SendInvoiceModal(props) {

    const [loadingModal, setLoadingModal] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [emailList, setEmailList] = useState([]);
    const [email, setEmail] = useState("");
    const [confirmModalText, setConfirmModalText] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const authCtx = useContext(AuthContext)

    useEffect(() => {
        setLoadingModal(true)
        fetchData()
    }, []);

    const fetchData = async () => {
        setLoadingModal(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get(`/v2/provider/email`, config);
            console.log(response)
            if (response.data.status == 200) {
                setEmail(response.data.message.result.email)
            }
            setLoadingModal(false)
        } catch (e) {
            setLoadingModal(false)
        }
    }

    const save = () => {
        setConfirmModalText("Please confirm you wish to Submit your Contractor Invoice to Genesis for payment and email a copy to the email address below for your records.")
        setIsPreview(false)
        setConfirmModal(true)
    }

    const preview = () => {
        setConfirmModalText("Please confirm you wish to preview your Contractor Invoice by having it emailed to the address below.")
        setIsPreview(true)
        setConfirmModal(true)
    }

    const exit = () => {
        props.setShowSendInvoice(false)
    }

    const saveConfirm = () => {
        setConfirmModal(false)
        props.sendInvoiceTo(email, isPreview)
    }

    const exitConfirm = () => {
        setConfirmModal(false)
    }

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <Fragment>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                {!confirmModal &&
                    <Fragment>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {"Contractor Invoice"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                loadingModal &&
                                <Loader
                                    type="Oval"
                                    color="#025959"
                                    height={50}
                                    width={50}
                                />
                            }
                            {
                                !loadingModal &&
                                <Fragment>
                                    <p>Click <b>Preview</b> to have an PDF invoice created for the Service Record you are viewing and emailed to the email address below.</p>
                                    <p>Click <b>Submit</b> to have an PDF invoice created for the Service Record you are viewing and emailed to the email address below as well as having your invoice Submitted to Genesis for payment.</p>
                                    <div class="form-group">
                                        <label>Destination Email:</label>
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                value={email}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </div>
                                </Fragment>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={exit} variant="secondary">{"Cancel"}</Button>
                            <Button onClick={preview}>{"Preview"}</Button>
                            <Button onClick={save}>{"Submit"}</Button>
                        </Modal.Footer>
                    </Fragment>
                }
            </Modal>

            <Modal
                show={confirmModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {isPreview ? "Preview Contractor Invoice" : "Submit Contractor Invoice"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Fragment>
                        <p>{confirmModalText} </p>
                        <p><strong>{email}</strong></p>
                    </Fragment>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={exitConfirm} variant="secondary">{"Cancel"}</Button>
                    <Button onClick={saveConfirm}>{"Confirm"}</Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}

export default SendInvoiceModal